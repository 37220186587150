import { useAppSelector } from "../hooks/useAppSelector";
import FactsTableContainer from "../components/facts/factsTableContainer";
import { IApiFact } from "../api/types";

export interface ExtensionsProps {
  isGeneralSidebarOpen: boolean;
  currentGeneralSidebarDrawerWidth: number;
  currentSelectedLanguage: string;
  allLanguages: string[];
  onChangeLang: (lang: string) => void;
}

const Extensions = ({
  isGeneralSidebarOpen,
  currentGeneralSidebarDrawerWidth,
  currentSelectedLanguage,
  allLanguages,
  onChangeLang,
}: ExtensionsProps) => {
  const relevantTargetNameSpaces =
    useAppSelector(
      (state) => state.extract.extractedBasicData?.entryPoints
    )?.map((ep) => ep?.namespace?.uri || "") || [];

  const allFacts = useAppSelector((state) =>
    state.extract.factType === "standard" ? state.extract.facts : []
  ) as IApiFact[];
  const allExtenseionFacts = allFacts.filter(
    (fact) =>
      fact.factElement.namespace &&
      relevantTargetNameSpaces.includes(fact.factElement.namespace.uri)
  );

  const excelExportFileName = useAppSelector(
    (state) =>
      `${`${state.extract.extractedBasicData?.packageName?.split(".")[0]} ` || ""}Extensions.xlsx`
  );

  return (
    <FactsTableContainer
      allFacts={allExtenseionFacts}
      isGeneralSidebarOpen={isGeneralSidebarOpen}
      currentGeneralSidebarDrawerWidth={currentGeneralSidebarDrawerWidth}
      title="Extensions"
      allLanguages={allLanguages}
      currentSelectedLanguage={currentSelectedLanguage}
      onChangeLang={onChangeLang}
      excelExportFileName={excelExportFileName}
      excelExportSheetName="extensions"
    />
  );
};

export default Extensions;
