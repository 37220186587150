import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Divider,
} from "@mui/material";

/* Translation of Text */
import { t, Trans } from "@lingui/macro";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IApiValidationResult } from "../../api/types";
import React from "react";

interface ValidationLogProps {
  header: string;
  validationResult?: IApiValidationResult;
  alteredText?: string[];
  alteredTextColor?: string;
}

export const ValidationLog = ({
  header,
  validationResult,
  alteredText,
  alteredTextColor,
}: ValidationLogProps) => {
  return validationResult === null ? null : (
    <Accordion
      defaultExpanded={true}
      sx={{ overflowX: "hidden", overflowY: "auto" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography data-downloadaspdf="validationlogheader" variant="h6">
          {header}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          minHeight: "100px",
          maxHeight: "1000px",
        }}>
        <>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              pb: 0.5,
            }}>
            {validationResult?.logEntries && (
              <Trans>The following validation rules were applied: </Trans>
            )}
          </Typography>
          <Grid
            item
            sm={12}
            sx={{
              maxHeight: "500px",
              overflowX: "hidden",
              overflowY: "auto",
            }}>
            {validationResult?.logEntries &&
              validationResult?.logEntries.map(
                (logEntry: string, index: number) => (
                  <React.Fragment key={index}>
                    <Typography
                      data-downloadaspdf="validationlogentry"
                      variant="body2"
                      sx={{
                        pb: 0.5,
                        lineHeight: 2,
                      }}>
                      {logEntry}
                    </Typography>
                    <Divider />
                  </React.Fragment>
                )
              )}
            {alteredText &&
              alteredText.map((text, index) => (
                <Typography
                  data-downloadaspdf="ConversionErrors"
                  variant="body2"
                  key={index}
                  sx={{
                    color: alteredTextColor ? alteredTextColor : "black",
                    pb: 0.5,
                    lineHeight: 2,
                  }}>
                  {text}
                  <Divider />
                </Typography>
              ))}
          </Grid>
        </>
        {!validationResult?.logEntries && !alteredText && (
          <Typography variant="body2" sx={{}}>
            {t`No logs were found.`}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
