import { Accept } from "react-dropzone";
import { ValidationElement } from "../api/types";
import { t } from "@lingui/macro";

export const userManualLink =
  "https://www.dropbox.com/scl/fi/d76zg41jlkct5ezvwbyka/ParsePort-Platform-User-Manual.pdf?rlkey=0mgz2g189ae5jo49j4snmqxuo&dl=1";

export const textSearchManualLink =
  "https://www.dropbox.com/scl/fi/p9x4yox7cpw5s9pl087x8/TextSearch_Manual_v4.1.pdf?rlkey=p0xmtfyglgo70oy898t1nfgoi&st=twbt250q&dl=1";

export const knowledgeBaseLink = "https://support.parseport.com/knowledge";
export const termsOfServiceLink = "https://parseport.com/legal/terms-inspector";

export const acceptedFileTypes = (
  hasInspector: boolean,
  hasConvertor: boolean
): Accept => {
  const conversionFiles = {
    "application/pdf": [".pdf"],
    "application/json": [".json"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
      ".xlsm",
    ],
    "text/html": [".html"],
    "application/vnd.ms-excel": [".xls"],
    "application/epub+zip": [".epub"],
  };
  const inspectionFiles = {
    "application/zip": [".zip"],
    "application/xhtml+xml": [".xhtml"],
    xbrl: [".xbrl", ".xbri"],
  };

  let output = {};
  if (hasConvertor) {
    output = Object.assign(output, conversionFiles);
  }
  if (hasInspector) {
    output = Object.assign(output, inspectionFiles);
  }
  return output;
};

export const acceptedFileExtensions = (
  hasInspector: boolean,
  hasConvertor: boolean
): string[] => {
  return Object.values(acceptedFileTypes(hasInspector, hasConvertor)).flatMap(
    (extArr) => extArr.map((ext) => ext.replace(".", ""))
  );
};
export const emailprefix = "support";

export const emailSuffix = "@parseport.com";

export const defaultConvertedPackageName = "convertedPackage.zip";

export const defaultHiddenColumnIds = [];

export const allowedXBRLTags = [
  "nonfraction",
  "continuation",
  "denominator",
  "exclude",
  "footnote",
  "fraction",
  "header",
  "hidden",
  "nonNumeric",
  "numerator",
  "references",
  "relationship",
  "resources",
  "tuple",
  "schemaRef",
  "linkbaseRef",
  "roleRef",
  "arcroleRef",
  "item",
  "context",
  "unit",
  "footnoteLink",
  "entity",
  "period",
  "scenario",
  "measure",
  "divide",
  "unitNumerator",
  "unitDenominator",
];

export const currencySymbols: { name: string; symbol: string }[] = [
  { name: "AED", symbol: "\u062F.\u0625" },
  { name: "ALL", symbol: "Lek" },
  { name: "AFN", symbol: "\u060B" },
  { name: "ARS", symbol: "$" },
  { name: "AWG", symbol: "\u0192" },
  { name: "AUD", symbol: "$" },
  { name: "AZN", symbol: "\u20BC" },
  { name: "BSD", symbol: "$" },
  { name: "BBD", symbol: "$" },
  { name: "BYN", symbol: "Br" },
  { name: "BZD", symbol: "BZ$" },
  { name: "BMD", symbol: "$" },
  { name: "BOB", symbol: "$b" },
  { name: "BAM", symbol: "KM" },
  { name: "BWP", symbol: "P" },
  { name: "BGN", symbol: "\u043B\u0432" },
  { name: "BRL", symbol: "R$" },
  { name: "BND", symbol: "$" },
  { name: "KHR", symbol: "\u17DB " },
  { name: "CAD", symbol: "$ " },
  { name: "KYD", symbol: "$ " },
  { name: "CLP", symbol: "$ " },
  { name: "CNY", symbol: "\u00A5" },
  { name: "COP", symbol: "$" },
  { name: "CRC", symbol: "\u20A1" },
  { name: "HRK", symbol: "kn" },
  { name: "CUP", symbol: "\u20B1" },
  { name: "CZK", symbol: "K\u010D" },
  { name: "DKK", symbol: "kr" },
  { name: "DOP", symbol: "RD$" },
  { name: "XCD", symbol: "$ " },
  { name: "EGP", symbol: "\u00A3" },
  { name: "SVC", symbol: "$" },
  { name: "EUR", symbol: "\u20AC" },
  { name: "FKP", symbol: "\u00A3" },
  { name: "FJD", symbol: "$" },
  { name: "GHS", symbol: "\u00A2" },
  { name: "GIP", symbol: "\u00A3" },
  { name: "GTQ", symbol: "Q" },
  { name: "GGP", symbol: "\u00A3" },
  { name: "GYD", symbol: "$" },
  { name: "HNL", symbol: "L" },
  { name: "HKD", symbol: "$" },
  { name: "HUF", symbol: "Ft" },
  { name: "ISK", symbol: "kr" },
  { name: "INR", symbol: "" },
  { name: "IDR", symbol: "Rp" },
  { name: "IRR", symbol: "\uFDFC" },
  { name: "IMP", symbol: "\u00A3" },
  { name: "ILS", symbol: "\u20AA" },
  { name: "JMD", symbol: "J$" },
  { name: "JPY", symbol: "\u00A5" },
  { name: "JEP", symbol: "\u00A3" },
  { name: "KZT", symbol: "\u043B\u0432" },
  { name: "KPW", symbol: "\u20A9" },
  { name: "KRW", symbol: "\u20A9" },
  { name: "KGS", symbol: "\u043B\u0432" },
  { name: "LAK", symbol: "\u20AD" },
  { name: "LBP", symbol: "\u00A3" },
  { name: "LRD", symbol: "$" },
  { name: "MKD", symbol: "\u0434\u0435\u043D" },
  { name: "MYR", symbol: "RM" },
  { name: "MUR", symbol: "\u20A8" },
  { name: "MXN", symbol: "$" },
  { name: "MNT", symbol: "\u20AE" },
  { name: "MZN", symbol: "MT" },
  { name: "NAD", symbol: "$" },
  { name: "NPR", symbol: "\u20A8" },
  { name: "ANG", symbol: "\u0192" },
  { name: "NZD", symbol: "$" },
  { name: "NIO", symbol: "C$" },
  { name: "NGN", symbol: "\u20A6" },
  { name: "NOK", symbol: "kr" },
  { name: "OMR", symbol: "\uFDFC" },
  { name: "PKR", symbol: "\u20A8" },
  { name: "PAB", symbol: "B/." },
  { name: "PYG", symbol: "Gs" },
  { name: "PEN", symbol: "S/." },
  { name: "PHP", symbol: "\u20B1" },
  { name: "PLN", symbol: "z\u0142" },
  { name: "QAR", symbol: "\uFDFC" },
  { name: "RON", symbol: "lei" },
  { name: "RUB", symbol: "\u20BD" },
  { name: "SHP", symbol: "\u00A3" },
  { name: "SAR", symbol: "\uFDFC" },
  { name: "RSD", symbol: "\u0414\u0438\u043D." },
  { name: "SCR", symbol: "\u20A8" },
  { name: "SGD", symbol: "$" },
  { name: "SBD", symbol: "$" },
  { name: "SOS", symbol: "S" },
  { name: "ZAR", symbol: "R" },
  { name: "LKR", symbol: "\u20A8" },
  { name: "SEK", symbol: "kr" },
  { name: "CHF", symbol: "CHF" },
  { name: "SRD", symbol: "$" },
  { name: "SYP", symbol: "\u00A3" },
  { name: "TWD", symbol: "NT$" },
  { name: "THB", symbol: "\u0E3F" },
  { name: "TTD", symbol: "TT$" },
  { name: "TRY", symbol: "" },
  { name: "TVD", symbol: "$" },
  { name: "UAH", symbol: "\u20B4 " },
  { name: "GBP", symbol: "\u00A3 " },
  { name: "USD", symbol: "$ " },
  { name: "UYU", symbol: "$U " },
  { name: "UZS", symbol: "\u043B\u0432" },
  { name: "VEF", symbol: "Bs" },
  { name: "VND", symbol: "\u20AB" },
  { name: "YER", symbol: "\uFDFC" },
  { name: "ZWD", symbol: "Z$" },
];

export const fileCombinations = [
  { files: ["excel"], code: null },
  { files: ["pdf"], code: null },
  { files: ["xhtml"], code: null },
  { files: ["epub"], code: null },
  { files: ["zip"], code: null },
  { files: ["excel", "pdf"], code: null },
  { files: ["excel", "xhtml"], code: null },
  { files: ["excel", "epub"], code: null },
  {
    files: ["excel", "zip"],
    code: {
      code: "",
      message: "Invalid file combination. Please remove Excel or Zip package.",
    },
  },
  {
    files: ["pdf", "xhtml"],
    code: {
      code: "ERR_PDF_XHTML",
      message: t`Invalid file combination. Please remove the PDF or XHTML file.`,
    },
  },
  {
    files: ["pdf", "epub"],
    code: {
      code: "ERR_PDF_EPUB",
      message: t`Invalid file combination. Please remove the PDF or EPUB file.`,
    },
  },
  {
    files: ["pdf", "zip"],
    code: {
      code: "ERR_PDF_EPUB",
      message: t`Invalid file combination. Please remove the PDF or Zip package.`,
    },
  },
  {
    files: ["xhtml", "epub"],
    code: {
      code: "ERR_XHTML_EPUB",
      message: t`Invalid file combination. Please remove the XHTML or EPUB file.`,
    },
  },
  {
    files: ["xhtml", "zip"],
    code: {
      code: "ERR_PDF_EPUB",
      message: t`Invalid file combination. Please remove the XHTML or Zip package.`,
    },
  },
  {
    files: ["epub", "zip"],
    code: {
      code: "ERR_EPUB_ZIP",
      message: t`Invalid file combination. Please remove the EPUB or ZIP file.`,
    },
  },
  {
    files: ["excel", "pdf", "xhtml"],
    code: {
      code: "ERR_EXCEL_PDF_XHTML",
      message: t`Invalid file combination. Please remove one of the files.`,
    },
  },
  {
    files: ["excel", "pdf", "epub"],
    code: {
      code: "ERR_EXCEL_PDF_EPUB",
      message: t`Invalid file combination. Please remove one of the files.`,
    },
  },
  {
    files: ["excel", "pdf", "zip"],
    code: {
      code: "ERR_EXCEL_PDF_EPUB",
      message: t`Invalid file combination. Please remove one of the files.`,
    },
  },
  {
    files: ["excel", "xhtml", "epub"],
    code: {
      code: "ERR_EXCEL_XHTML_EPUB",
      message: t`Invalid file combination. Please remove one of the files.`,
    },
  },
  {
    files: ["excel", "xhtml", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["excel", "epub", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["pdf", "xhtml", "epub"],
    code: { code: "ERR_PDF_XHTML_EPUB", message: t`Invalid file combination.` },
  },
  {
    files: ["pdf", "xhtml", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["pdf", "epub", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["xhtml", "epub", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["excel", "pdf", "xhtml", "epub"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["excel", "pdf", "xhtml", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["excel", "pdf", "epub", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["excel", "xhtml", "epub", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["pdf", "xhtml", "epub", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
  {
    files: ["excel", "pdf", "xhtml", "epub", "zip"],
    code: {
      code: "ERR_INVALID_COMBINATION",
      message: t`Invalid file combination`,
    },
  },
];

export const getAccuracyDisplayValue = (
  decimals: string | undefined
): string => {
  if (!decimals) return "";
  else if (decimals.toLowerCase() === "inf") return "(Infinite)";
  const dec = parseInt(decimals);
  if (isNaN(dec)) return "";
  const absDec = Math.abs(dec);
  let desc = "";
  if (absDec === 1) desc = "(Tens)";
  else if (absDec === 2) desc = "(Hundreds)";
  else if (absDec === 3) desc = "(Thousands)";
  else if (absDec === 4) desc = "(Ten thousands)";
  else if (absDec === 5) desc = "(Hundred thousands)";
  else if (absDec === 6) desc = "(Millions)";
  return `${dec} ${desc}`;
};

export const minimumGeneralSidebarDrawerWidth = 500;
export const secondarySidebarSize = 400;
export const appBarFixedHeight = 72;

export const pathsForPagesThatHaveSidebarAvailable = [
  "viewer",
  "facts",
  "extensions",
  "presentation",
];

export const viewerReportContainerClassName = "xbrl_report_viewer_container";

export enum ToolbarActions {
  selectLanguage,
  download,
  zoomIn,
  zoomOut,
  zoomReset,
  highlightErrors,
  highlightFacts,
  showSidebar,
  showFilterMenu,
  previousFact,
  nextFact,
  downloadReport,
  downloadPrintableReport,
  downloadSummary,
  openInViewer,
  filterColumns,
  resetToDefaults,
  downloadPlainXbrl,
  downloadZip,
  updload,
  showDebugView,
}

export const taxonomyChecks = [
  "tpe:",
  "xbrl.1",
  "xbrl.2",
  "xbrl.3",
  "xbrl.4",
  "ESEF.RTS",
  "ESEF.1",
  "ESEF.2",
  "ESEF.3.1",
  "utre",
  "xbrldie",
];

export const xHTMLerrorChecks = [
  "xmlSchema",
  "ix11",
  "html:",
  "message:",
  "arelle:duplicateFacts",
];

export const entryPointsChecks = [
  "xbrl.5.1",
  "ESEF.3.2",
  "ESEF.3.3",
  "ESEF.3.5",
];

export const linkBaseChecks = [
  "xbrl.5.2",
  "ESEF.3.4",
  "xlink:",
  "link:",
  "calc11e:inconsistentCalculationUsingRounding",
  "calc11e:inconsistentCalculationUsingTruncation",
  "calc11e:disallowedDuplicateFactsUsingTruncation",
  "calc11e:duplicateCalculationRelationships",
  "calc11e:excessDigits",
  "calc11e:inconsistentCalculationUsingRounding",
  "calc11e:inconsistentCalculationUsingTruncation",
  "calc11e:oimIncompatibleReportWarning",
  "calc11e:tuplesInReportWarning",
  "oime:disallowedDuplicateFacts",
];

export const xMLerrorChecks = ["XmlSchemaError", "lxml"];

export const xbrlSubStringValue = 7;

export const calc11 = "calc11";
export const eSEFTag = "ESEF";
export const eSEFRTSTag = "ESEF.RTS";
export const annexITag = "Annex.I.";
export const annexIITag = "Annex.II.";
export const annexIIITag = "Annex.III.";
export const annexIVTag = "Annex.IV.";
export const annexVTag = "Annex.V.";
export const annexVITag = "Annex.VI.";
export const xBrlTag = "xbrl";
export const ix11Tag = "ix11";

export const errorIdcalc11 =
  "https://www.xbrl.org/Specification/calculation-1.1/CR-2022-10-12/calculation-1.1-CR-2022-10-12.html";
export const errorIdAnnexIRef =
  "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32019R0815&from=EN#d1e32-5-1";
export const errorIdAnnexIIRef =
  "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32019R0815&from=EN#d1e32-6-1";
export const errorIdAnnexIIIRef =
  "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32019R0815&from=EN#d1e32-20-1";
export const errorIdAnnexIVRef =
  "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32019R0815&from=EN#d1e32-21-1";
export const errorIdAnnexVRef =
  "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32019R0815&from=EN#d1e32-23-1";
export const errorIdAnnexVIRef =
  "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32019R0815&from=EN#d1e32-24-1";
export const errorIdCELEXRef =
  "https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32019R0815&from=EN#d1e32-24-1";
export const errorIdESMARef =
  "https://www.esma.europa.eu/sites/default/files/library/esma32-60-254_esef_reporting_manual.pdf";
export const errorIdXBRLRef =
  "http://www.xbrl.org/Specification/XBRL-2.1/REC-2003-12-31/XBRL-2.1-REC-2003-12-31+corrected-errata-2013-02-20.html#_";
export const errorIdIx11Ref =
  "http://www.xbrl.org/specification/inlinexbrl-part1/rec-2013-11-18/inlinexbrl-part1-rec-2013-11-18.html";

export const gleifUrl = "https://search.gleif.org/#/record/";
export const gleifPublicUrl = "https://www.gleif.org/en";

export const nablNL = "https://www.nba.nl/reporting-package-hash-generator";
export const taxonomyTreeSearchMinimumCharCount = 3;

export const authEndpoints = [
  "microsoft",
  "forgot",
  "reset",
  "options",
  "login",
];

export const downloadSummaryFileNameSuffix = "_validationsummary";
export const validationSummaryRootElementId = "validation_summary_root_element";
export const importErrorsShowcase = "Import Errors";
export const taxonomyCheckErrorShowcase = "Taxonomypackage Check";
export const XMLCheckErrorShowcase = "XML Error Check";
export const entryPointsCheckErrorShowcase = "Entrypoints Check";
export const xHTMLCheckErrorShowcase = "xHTML Check";
export const linkBaseCheckErrorShowcase = "Linkbase Check";
export const importErrors = "Import Errors";

export const gleifApiUrl =
  "https://api.gleif.org/api/v1/lei-records?filter[lei]=";

export const ixbrlViewerCDNUrl =
  "https://cdn-prod.wdesk.com/ixbrl-viewer/1.0.0/ixbrlviewer.js";

export const CSVDelimiter = ";";
export const CSVLineBreakChar = "\n";

export const factsExcelExportColumnIds: string[] = [
  "label",
  "name",
  "language",
  "unit",
  "formattedValue",
  "balanceType",
  "periodType",
  "itemType",
  "decimals",
  "scale",
  "periodStart",
  "periodEnd",
  "dimensions",
  "dimensionMembers",
  "dimensionValues",
  "wideAnchor",
  "narrowerAnchor",
  "footnotes",
  "tableName",
  "rowCode",
  "columnCode",
];

export const changedFactsExcelExportColumnIds: string[] = [
  "changeType",
  "changedProps",
  ...factsExcelExportColumnIds,
];

export const factsExcelExportFileName = "facts.xlsx";
export const extensionsExcelExportFileName = "extensions.xlsx";
export const compareExcelExportFileName = "package_comparison.xlsx";
export const mandatoryTagsExcelExportFileName = "mandatory_tags.xlsx";
export const defaultTableRowsPerPage = 50;

export const reportErrorColor = (
  severity: ValidationElement["severity"] | null
): string => {
  if (severity === "Error" || severity === "FatalError") return "red";
  if (severity === "Warning") return "darkorange";
  if (severity === "Inconsistency") return "dodgerblue";
  return "";
};

export const successColor = "#8AE991";
export const errorColor = "#E97575";
export const warningColor = "darkorange";
export const inconsistencyColor = "#0094C9";
export const infoColor = "black";

export const anchoringArcrole =
  "http://www.esma.europa.eu/xbrl/esef/arcrole/wider-narrower";

export const loginWithPasswordRelativeUrl = "/api/accounts/login";

export const emptyGuidValue = "00000000-0000-0000-0000-000000000000";

export const numberOfProgressStepsToShow = -1;
export const signalRHubUrl = "/logging/signalr/conversion-progress";
export const signalRMessageSubscriptionMethodName = "messageReceived";
export const factValueTextLengthCutoff = 500;

export const pagingKeyCodes = [
  "ArrowLeft",
  "ArrowRight",
  "PageUp",
  "PageDown",
  "Home",
  "End",
];

export const signalrInternalTicketAbsoluteUrl = `${process.env.REACT_APP_SIGNALR_BASE_URL}/logging/api/tickets/generate`;

export const viewerXBRLCell = "viewer_replaced_xbrl_cell";

export const orderOfTags = [
  "Name of reporting entity or other means of identification",
  "Explanation of change in name of reporting entity or other means of identification from end of preceding reporting period",
  "Domicile of entity",
  "Legal form of entity",
  "Country of incorporation",
  "Address of entity's registered office",
  "Principal place of business",
  "Description of nature of entity's operations and principal activities",
  "Name of parent entity",
  "Name of ultimate parent of group",
  "Length of life of limited life entity",
  "Statement of IFRS compliance [text block]",
  "Explanation of departure from IFRS",
  "Explanation of financial effect of departure from IFRS",
  "Disclosure of uncertainties of entity's ability to continue as going concern  [text block]",
  "Explanation of fact and basis for preparation of financial statements when not going concern basis",
  "Explanation of why entity not regarded as going concern",
  "Description of reason for using longer or shorter reporting period",
  "Description of fact that amounts presented in financial statements are not entirely comparable",
  "Disclosure of reclassifications or changes in presentation [text block]",
  "Explanation of sources of estimation uncertainty with significant risk of causing material adjustment",
  "Disclosure of assets and liabilities with significant risk of material adjustment [text block]",
  "Dividends recognised as distributions to owners per share",
  "Dividends proposed or declared before financial statements authorised for issue but not recognised as distribution to owners",
  "Dividends proposed or declared before financial statements authorised for issue but not recognised as distribution to owners per share",
  "Disclosure of accounting judgements and estimates [text block]",
  "Disclosure of accrued expenses and other liabilities [text block]",
  "Disclosure of allowance for credit losses [text block]",
  "Disclosure of associates [text block]",
  "Disclosure of auditors' remuneration  [text block]",
  "Disclosure of authorisation of financial statements [text block]",
  "Disclosure of available -for-sale financial assets [text block]",
  "Disclosure of basis of consolidation [text block]",
  "Disclosure of basis of preparation of financial statements [text block]",
  "Disclosure of biological assets, agriculture produce at point of harvest and government grants related to biological assets [text block]",
  "Disclosure of borrowings [text block]",
  "Disclosure of business combinations [text block]",
  "Disclosure of cash and bank balances at central banks [text block]",
  "Disclosure of cash and cash equivalents [text block]",
  "Disclosure of cash flow statement [text block]",
  "Disclosure of changes in accounting policies [text block]",
  "Disclosure of changes in accounting policies, accounting estimates and errors [text block]",
  "Disclosure of collateral [text block]",
  "Disclosure of claims and benefits paid [text block]",
  "Disclosure of commitments [text block]",
  "Disclosure of commitments and contingent liabilities [text block]",
  "Disclosure of contingent liabilities [text block]",
  "Disclosure of cost of sales [text block]",
  "Disclosure of credit risk [text block]",
  "Disclosure of debt instruments [text block]",
  "Disclosure of deferred acquisition costs arising from insurance contracts [text block]",
  "Disclosure of deferred income [text block]",
  "Disclosure of deferred taxes [text block]",
  "Disclosure of deposits from banks [text block]",
  "Disclosure of deposits from customers [text block]",
  "Disclosure of depreciation and amortisation expense [text block]",
  "Disclosure of derivative financial instruments [text block]",
  "Disclosure of discontinued operations [text block]",
  "Disclosure of dividends [text block]",
  "Disclosure of earnings per share [text block]",
  "Disclosure of effect of changes in foreign exchange rates [text block]",
  "Disclosure of employee benefits [text block]",
  "Disclosure of entity's operating segments  [text block]",
  "Disclosure of events after reporting period [text block]",
  "Disclosure of expenses [text block]",
  "Disclosure of expenses by nature [text block]",
  "Disclosure of exploration and evaluation assets [text block]",
  "Disclosure of fair value measurement [text block]",
  "Disclosure of fair value of financial instruments [text block]",
  "Disclosure of fee and commission income(expense) [text block]",
  "Disclosure of finance cost [text block]",
  "Disclosure of finance income(cost) [text block]",
  "Disclosure of finance income [text block]",
  "Disclosure of financial assets held for trading [text block]",
  "Disclosure of financial instruments [text block]",
  "Disclosure of financial instruments at fair value through profit or loss [text block]",
  "  Disclosure of financial instruments designated at fair value through profit or loss [text block]",
  "Disclosure of financial instruments held for trading [text block]",
  "Disclosure of financial liabilities held for trading [text block]",
  "Disclosure of financial risk management [text block]",
  "Disclosure of first - time adoption [text block]",
  "Disclosure of general and administrative expense [text block]",
  "Disclosure of general information about financial statements [text block]",
  "Disclosure of going concern [text block]",
  "Disclosure of goodwill [text block]",
  "Disclosure of government grants [text block]",
  "Disclosure of impairment of assets [text block]",
  "Disclosure of income tax [text block]",
  "Disclosure of information about employees [text block]",
  "Disclosure of information about key management personnel [text block]",
  "Disclosure of insurance contracts [text block]",
  "Disclosure of insurance premium revenue [text block]",
  "Disclosure of intangible assets [text block]",
  "Disclosure of intangible assets and goodwill [text block]",
  "Disclosure of interest expense [text block]",
  "Disclosure of interest income [text block]",
  "Disclosure of interest income (expense) [text block]",
  "Disclosure of inventories [text block]",
  "Disclosure of investment contracts liabilities [text block]",
  "Disclosure of investment property [text block]",
  "Disclosure of investments accounted for using equity method  [text block]",
  "Disclosure of investments other than investments accounted for using equity method  [text block]",
  "Disclosure of issued capital [text block]",
  "Disclosure of joint ventures [text block]",
  "Disclosure of lease prepayments [text block]",
  "Disclosure of leases [text block]",
  "Disclosure of liquidity risk [text block]",
  "Disclosure of loans and advances to banks [text block]",
  "Disclosure of loans and advances to customers [text block]",
  "Disclosure of market risk [text block]",
  "Disclosure of net asset value attributable to unit - holders [text block]",
  "Disclosure of non - controlling interests [text block]",
  "Disclosure of non - current assets held for sale and discontinued operations [text block]",
  "Disclosure of non - current assets or disposal groups classified as held for sale [text block]",
  "Disclosure of objectives, policies and processes for managing capital [text block]",
  "Disclosure of other assets [text block]",
  "Disclosure of other current assets [text block]",
  "Disclosure of other current liabilities [text block]",
  "Disclosure of other liabilities [text block]",
  "Disclosure of other non - current assets [text block]",
  "Disclosure of other non - current liabilities [text block]",
  "Disclosure of other operating expense [text block]",
  "Disclosure of other operating income(expense) [text block]",
  "Disclosure of other operating income [text block]",
  "Disclosure of prepayments and other assets [text block]",
  "Disclosure of profit(loss) from operating activities [text block]",
  "Disclosure of property, plant and equipment [text block]",
  "Disclosure of provisions [text block]",
  "Disclosure of reclassification of financial instruments [text block]",
  "Disclosure of reinsurance [text block]",
  "Disclosure of related party [text block]",
  "Disclosure of repurchase and reverse repurchase agreements [text block]",
  "Disclosure of research and development expense [text block]",
  "Disclosure of reserves within equity [text block]",
  "Disclosure of restricted cash and cash equivalents [text block]",
  "Disclosure of revenue [text block]",
  "Disclosure of service concession arrangements [text block]",
  "Disclosure of share capital, reserves and other equity interest [text block]",
  "Disclosure of share - based payment arrangements [text block]",
  "Disclosure of subordinated liabilities [text block]",
  "Disclosure of subsidiaries [text block]",
  "Disclosure of significant accounting policies [text block]",
  "Disclosure of tax receivables and payables [text block]",
  "Disclosure of trade and other payables [text block]",
  "Disclosure of trade and other receivables [text block]",
  "Disclosure of trading income(expense) [text block]",
  "Disclosure of treasury shares [text block]",
  "Description of accounting policy for available -for-sale financial assets [text block]",
  "Description of accounting policy for biological assets [text block]",
  "Description of accounting policy for borrowing costs [text block]",
  "Description of accounting policy for borrowings [text block]",
  "Description of accounting policy for business combinations [text block]",
  "Description of accounting policy for business combinations and goodwill [text block]",
  "Description of accounting policy for cash flows [text block]",
  "Description of accounting policy for collateral [text block]",
  "Description of accounting policy for construction in progress [text block]",
  "Description of accounting policy for contingent liabilities and contingent assets [text block]",
  "Description of accounting policy for customer acquisition costs [text block]",
  "Description of accounting policy for customer loyalty programmes [text block]",
  "Description of accounting policy for decommissioning, restoration and rehabilitation provisions [text block]",
  "Description of accounting policy for deferred acquisition costs arising from insurance contracts [text block]",
  "Description of accounting policy for deferred income tax [text block]",
  "Description of accounting policy for depreciation expense [text block]",
  "Description of accounting policy for derecognition of financial instruments [text block]",
  "Description of accounting policy for derivative financial instruments [text block]",
  "Description of accounting policy for derivative financial instruments and hedging [text block]",
  "Description of accounting policy for determining components of cash and cash equivalents [text block]",
  "Description of accounting policy for discontinued operations [text block]",
  "Description of accounting policy for discounts and rebates [text block]",
  "Description of accounting policy for dividends [text block]",
  "Description of accounting policy for earnings per share [text block]",
  "Description of accounting policy for emission rights [text block]",
  "Description of accounting policy for employee benefits [text block]",
  "Description of accounting policy for environment related expense [text block]",
  "Description of accounting policy for exceptional items [text block]",
  "Description of accounting policy for expenses [text block]",
  "Description of accounting policy for exploration and evaluation expenditures [text block]",
  "Description of accounting policy for fair value measurement [text block]",
  "Description of accounting policy for fee and commission income and expense [text block]",
  "Description of accounting policy for finance costs [text block]",
  "Description of accounting policy for finance income and costs [text block]",
  "Description of accounting policy for financial assets [text block]",
  "Description of accounting policy for financial guarantees [text block]",
  "Description of accounting policy for financial instruments [text block]",
  "Description of accounting policy for financial instruments at fair value through profit or loss [text block]",
  "Description of accounting policy for financial liabilities [text block]",
  "Description of accounting policy for foreign currency translation [text block]",
  "Description of accounting policy for franchise fees [text block]",
  "Description of accounting policy for functional currency [text block]",
  "Description of accounting policy for goodwill [text block]",
  "Description of accounting policy for government grants [text block]",
  "Description of accounting policy for hedging [text block]",
  "Description of accounting policy for held - to - maturity investments [text block]",
  "Description of accounting policy for impairment of assets [text block]",
  "Description of accounting policy for impairment of financial assets [text block]",
  "Description of accounting policy for impairment of non - financial assets [text block]",
  "Description of accounting policy for income tax [text block]",
  "Description of accounting policy for insurance contracts and related assets, liabilities, income and expense [text block]",
  "Description of accounting policy for intangible assets and goodwill [text block]",
  "Description of accounting policy for intangible assets other than goodwill [text block]",
  "Description of accounting policy for interest income and expense [text block]",
  "Description of accounting policy for investment in associates [text block]",
  "Description of accounting policy for investment in associates and joint ventures [text block]",
  "Description of accounting policy for investments in joint ventures [text block]",
  "Description of accounting policy for investment property [text block]",
  "Description of accounting policy for investments other than investments accounted for using equity method  [text block]",
  "Description of accounting policy for issued capital [text block]",
  "Description of accounting policy for leases [text block]",
  "Description of accounting policy for loans and receivables [text block]",
  "Description of accounting policy for measuring inventories [text block]",
  "Description of accounting policy for mining assets [text block]",
  "Description of accounting policy for mining rights [text block]",
  "Description of accounting policy for non - current assets or disposal groups classified as held for sale [text block]",
  "Description of accounting policy for non - current assets or disposal groups classified as held for sale and discontinued operations [text block]",
  "Description of accounting policy for offsetting of financial instruments [text block]",
  "Description of accounting policy for oil and gas assets [text block]",
  "Description of accounting policy for programming assets [text block]",
  "Description of accounting policy for property, plant and equipment [text block]",
  "Description of accounting policy for provisions [text block]",
  "Description of accounting policy for reclassification of financial instruments [text block]",
  "Description of accounting policy for recognising in profit or loss difference between fair value at initial recognition and transaction price [text block]",
  "Description of accounting policy for recognition of revenue [text block]",
  "Description of accounting policy for regulatory deferral accounts [text block]",
  "Description of accounting policy for reinsurance [text block]",
  "Description of accounting policy for repairs and maintenance [text block]",
  "Description of accounting policy for repurchase and reverse repurchase agreements [text block]",
  "Description of accounting policy for research and development expense [text block]",
  "Description of accounting policy for restricted cash and cash equivalents [text block]",
  "Description of accounting policy for segment reporting [text block]",
  "Description of accounting policy for service concession arrangements [text block]",
  "Description of accounting policy for share - based payment transactions [text block]",
  "Description of accounting policy for stripping costs [text block]",
  "Description of accounting policy for subsidiaries [text block]",
  "Description of accounting policy for taxes other than income tax [text block]",
  "Description of accounting policy for termination benefits [text block]",
  "Description of accounting policy for trade and other payables [text block]",
  "Description of accounting policy for trade and other receivables [text block]",
  "Description of accounting policy for trading income and expense [text block]",
  "Description of accounting policy for transactions with non - controlling interests [text block]",
  "Description of accounting policy for treasury shares [text block]",
  "Disclosure of borrowing costs [text block]",
  "Disclosure of separate financial statements [text block]",
  "Disclosure of hyperinflationary reporting [text block]",
  "Disclosure of interests in other entities [text block]",
  "Disclosure of interim financial reporting [text block]",
  "Disclosure of notes and other explanatory information [text block]",
  "Disclosure of other provisions, contingent liabilities and contingent assets [text block]",
  "Disclosure of regulatory deferral accounts [text block]",
  "Disclosure of revenue from contracts with customers  [text block]",
  "Description of accounting policy for transactions with related parties  [text block]",
  "Description of accounting policy for warrants  [text block]",
  "Description of other accounting policies relevant to understanding of financial statements  [text block]",
];

export const generalEpubConversionIllegalStyleDirectiveText =
  "body, div, dl, dt, dd, h1, h2, h3, h4, h5, h6, p, pre, code, blockquote { margin: 0px; padding: 0px; border-width: 0px; text-rendering: optimizespeed; }";

export const internalValidationErrorIds = {
  hidden: "e43455ff-c287-464a-89df-43e971c0eb87",
  hiddenTransformable: "e3599191-48fe-4837-a017-7f2fb4750192",
  inconsistentDuplicate: "6c33c99a-9e7e-4480-8b86-4fc67662ffee",
  inconsistentDecimal: "8216c7d6-2089-46a5-8eff-99a8dc781c00",
  unicodeChar: "1650e4df-14e4-4385-b92d-61c0a115610e",
};

export const unicodeClassName = "pp-unicode";

export const countryCodes: { country: string; code: string }[] = [
  { country: "Norway", code: "NO" },
  { country: "United Kingdom", code: "UK" },
  { country: "Austria", code: "AT" },
  { country: "Belgium", code: "BE" },
  { country: "Bulgaria", code: "BG" },
  { country: "Cyprus", code: "CY" },
  { country: "Czech Republic", code: "CZ" },
  { country: "Switzerland", code: "CH" },
  { country: "Germany", code: "DE" },
  { country: "Denmark", code: "DK" },
  { country: "Estonia", code: "EE" },
  { country: "Spain", code: "ES" },
  { country: "Finland", code: "FI" },
  { country: "France", code: "FR" },
  { country: "Greece", code: "GR" },
  { country: "Croatia", code: "HR" },
  { country: "Hungary", code: "HU" },
  { country: "Ireland", code: "IE" },
  { country: "Iceland", code: "IS" },
  { country: "Italy", code: "IT" },
  { country: "Lithuania", code: "LT" },
  { country: "Luxembourg", code: "LU" },
  { country: "Latvia", code: "LV" },
  { country: "Malta", code: "MT" },
  { country: "Netherlands", code: "NL" },
  { country: "Poland", code: "PL" },
  { country: "Portugal", code: "PT" },
  { country: "Romania", code: "RO" },
  { country: "Sweden", code: "SE" },
  { country: "Slovenia", code: "SI" },
  { country: "Slovakia", code: "SK" },
  { country: "United Kingdom", code: "GB" },
];

export const defaultLabelTypeUri = "http://www.xbrl.org/2003/role/label";

export const calculationTypes = {
  Calculation10: `Calculation 1.0`,
  Calculation11RoundToNearest: `Calculation 1.1 round to nearest`,
  Calculation11Truncation: `Calculation 1.1 truncation`,
};

export const identifierFactName =
  "ifrs-full:NameOfReportingEntityOrOtherMeansOfIdentification";

export const enumerationReferenceElementStyleValue = "-esef-ix-hidden";

export const enumerationItemTypeName = "enum2:enumerationItemType";
export const enumerationSetItemTypeName = "enum2:enumerationSetItemType";
