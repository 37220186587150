import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  ExtractedTaxonomy,
  IApiFact,
  ExtractedElementTree,
  ExtractedRoleType,
  ExtractedLinkbaseTree,
  LinkbaseTreeMapping,
  LinkbaseTreeUsedElementsMapping,
  IApiRoleTypeWithElementIds,
  IApiEurofilingFact,
  LabelsForElementByName,
} from "../api/types";
import {
  extractFactsFromReport,
  extract,
  extractFacts,
  extractTaxonomyFromReport,
  getLinkbaseTreeElementChildren,
  getLinkbaseTreeRoleTypeChildren,
  getLinkbaseRoleTypeWithDescendentsByElement,
  getLinkbaseTreeByEntryPoint,
  getReportFileHashCode,
  getUsedRoleTypesAndElementIds,
  getLabelsByNamespaceAndElementName,
} from "../api/taxonomies";
import { getLinkbaseTreeBranchById } from "../helpers/getLinkbaseTreeBranchById";
import { handleUpdateLinkbaseTreesResponse } from "../helpers/handleUpdateLinkbaseTreesResponse";
import { getUniqueArray } from "../helpers/getUniqueArrayByPoprties";
import { conversionFlagsCleared } from "./conversionReducer";
import {
  importIdCreated,
  downloadedPayloadRetrieved,
  uploadedFileNamesUpdated,
  filesCleared,
} from "./importReducer";
import { mappingExtractedDataCleared } from "./mappingReducer";
import { clearLifeCycleState } from "./internalLifeCycleReducer";
import { getCachedPackage } from "../helpers/getCachedPackage";

export interface IExtractionState {
  facts: Array<IApiFact | IApiEurofilingFact>;
  enumerationFactValueLabelMapping: Array<{
    factId: string;
    labels: LabelsForElementByName[];
  }>;
  factType: "standard" | "eurofiling";
  factExtractionPending: boolean;
  factsExtractionError: boolean;
  extractedBasicData: ExtractedTaxonomy | null;
  basicDataExtractionPending: boolean;
  basicDataExtractionError: boolean;
  extractionType: "package" | "reportFile" | undefined;
  extractionCancelled: boolean;
  updatingLinkbaseTree: boolean;
  updatingElementLabels: boolean;
  updatingLinkbaseTreeError: boolean;
  linkbaseTreeMappings: LinkbaseTreeMapping[];
  getLinkbaseTreeByEntryPointPending: boolean;
  reportFileHashCode: string;
  linkbaseTreeUsedElementsMapping: LinkbaseTreeUsedElementsMapping[];
}

const initialState: IExtractionState = {
  factsExtractionError: false,
  basicDataExtractionError: false,
  factExtractionPending: false,
  facts: [],
  basicDataExtractionPending: false,
  extractedBasicData: null,
  extractionType: undefined,
  updatingLinkbaseTree: false,
  updatingLinkbaseTreeError: false,
  updatingElementLabels: false,
  linkbaseTreeMappings: [],
  getLinkbaseTreeByEntryPointPending: false,
  extractionCancelled: false,
  reportFileHashCode: "",
  linkbaseTreeUsedElementsMapping: [],
  factType: "standard",
  enumerationFactValueLabelMapping: [],
};

export interface PackageDetails {
  packageName: string;
  packageUrl: string;
}

export const startExtractFacts = createAsyncThunk(
  "extract/extractfacts/start",
  async (
    args: {
      packageFile: File;
      type: IExtractionState["extractionType"];
      abortSignal?: AbortSignal;
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    const formData = new FormData();
    formData.append("File", args.packageFile);
    try {
      const state = getState() as RootState;
      let importId = state.import.importId;
      if (importId.length === 0) {
        importId = crypto.randomUUID();
        await dispatch(importIdCreated(importId));
      }
      const action =
        args.type === "reportFile" ? extractFactsFromReport : extractFacts;
      const resp = await action(formData, importId, args.abortSignal);
      if (resp.data.length > 0 && "tableName" in resp.data[0] === false) {
        dispatch(filesCleared(null));
      }
      return resp.data;
    } catch (ex) {
      return rejectWithValue(ex);
    }
  }
);

export const startExtractBasicData = createAsyncThunk<
  ExtractedTaxonomy,
  {
    packageFile: File | Blob;
    savePackage: boolean;
    extractionType: IExtractionState["extractionType"];
    abortSignal?: AbortSignal;
  }
>(
  "extract/extract/start",
  async (
    args: {
      packageFile: File | Blob;
      savePackage: boolean;
      extractionType: IExtractionState["extractionType"];
      abortSignal?: AbortSignal;
    },
    { rejectWithValue, getState, dispatch }
  ): Promise<ExtractedTaxonomy> => {
    const state = getState() as RootState;
    let importId = state.import.importId;
    if (importId.length === 0) {
      importId = crypto.randomUUID();
      await dispatch(importIdCreated(importId));
    }
    const formData = new FormData();
    formData.append("File", args.packageFile);
    try {
      const action =
        args.extractionType === "package" ? extract : extractTaxonomyFromReport;
      const resp = await action(formData, importId, args.abortSignal);
      dispatch(mappingExtractedDataCleared(null));
      dispatch(conversionFlagsCleared(null));
      dispatch(clearLifeCycleState(null));
      dispatch(uploadedFileNamesUpdated([]));
      if (args.savePackage) {
        dispatch(
          downloadedPayloadRetrieved({
            downloadedPayloadUrl: URL.createObjectURL(args.packageFile),
            downloadedPayloadName: (args.packageFile as File).name,
            downloadedPayloadType:
              (args.packageFile as File).name.toLowerCase().split(".")[
                (args.packageFile as File).name.toLowerCase().split(".")
                  .length - 1
              ] === "zip" ||
                (args.packageFile as File).name.toLowerCase().split(".")[
                (args.packageFile as File).name.toLowerCase().split(".")
                  .length - 1
                ] === "xbri"
                ? "package"
                : "viewerFile"
          })
        );
      }
      return resp.data;
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const startUpdateLinkbaseTree = createAsyncThunk(
  "extract/linkbasetree/update",
  async (
    args: {
      treeId: string;
      roleTypeId?: string;
      elementId?: string;
      elementNamespaceUri?: string;
    },
    { rejectWithValue }
  ): Promise<ExtractedElementTree[] | ExtractedRoleType | undefined> => {
    try {
      let resp;
      if (args.roleTypeId) {
        if (args.elementId) {
          resp = await getLinkbaseTreeElementChildren(
            args.treeId,
            args.roleTypeId,
            args.elementId
          );
        } else {
          resp = await getLinkbaseTreeRoleTypeChildren(
            args.treeId,
            args.roleTypeId
          );
        }
      } else if (args.elementNamespaceUri && args.elementId && args.treeId) {
        resp = await getLinkbaseRoleTypeWithDescendentsByElement(
          args.treeId,
          args.elementNamespaceUri,
          args.elementId
        );
      }
      if (!resp || !resp?.data) {
        console.log("Error updating linkbase tree");
        throw rejectWithValue("Error updating linkbase tree");
      }
      return resp.data;
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const startGetLinkbaseTreeByEntryPoint = createAsyncThunk(
  "extract/linkbasetree/byentrypoint",
  async (
    args: {
      entryPointHref: string;
      type: ExtractedLinkbaseTree["type"];
    },
    { rejectWithValue }
  ): Promise<ExtractedLinkbaseTree> => {
    try {
      const resp = await getLinkbaseTreeByEntryPoint(
        args.entryPointHref,
        args.type
      );
      return resp.data;
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const startGetElementLabelsByNamespaceAndElementName = createAsyncThunk(
  "extract/element/labels",
  async (
    args: {
      factId: string;
      enumerationValuesToRetrieve: Record<string, string>;
    },
    { rejectWithValue }
  ): Promise<LabelsForElementByName[]> => {
    try {
      const resp = await getLabelsByNamespaceAndElementName(
        args.enumerationValuesToRetrieve
      );
      return resp.data;
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const startGetReportFileHashCode = createAsyncThunk(
  "extract/report/hashcode",
  async (
    args: {
      reportFileUrl: string;
      reportFileName: string;
      abortSignal?: AbortSignal;
    },
    { rejectWithValue }
  ): Promise<string> => {
    try {
      const blob = await getCachedPackage(args.reportFileUrl);
      const uploadFile = new File([blob], args.reportFileName);
      const formData = new FormData();
      formData.append("file", uploadFile);
      return getReportFileHashCode(formData, args.abortSignal);
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

export const startGetUsedElementIdsForLinkbaseTree = createAsyncThunk(
  "extract/linkbasetree/getUsedElementIds",
  async (
    args: {
      treeId: string;
      elementIdsUsedInReport: string[];
      abortSignal?: AbortSignal;
    },
    { rejectWithValue }
  ): Promise<IApiRoleTypeWithElementIds[]> => {
    try {
      const resp = await getUsedRoleTypesAndElementIds(
        args.treeId,
        JSON.stringify(args.elementIdsUsedInReport),
        args.abortSignal
      );
      return resp.data;
    } catch (ex) {
      throw rejectWithValue(ex);
    }
  }
);

const extractionSlice: Slice<IExtractionState> = createSlice({
  name: "extract",
  initialState: initialState,
  reducers: {
    extractionCleared: (state): IExtractionState => {
      return {
        ...state,
        extractedBasicData: null,
        facts: [],
      };
    },
    extractionTypeUpdated: (state, action): IExtractionState => {
      return {
        ...state,
        extractionType: action.payload,
      };
    },
    extractionErrorsCleared: (state): IExtractionState => {
      return {
        ...state,
        factsExtractionError: false,
        basicDataExtractionError: false,
        extractionCancelled: false,
      };
    },
    factsUpdated: (state, action): IExtractionState => {
      return {
        ...state,
        facts: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startExtractFacts.pending, (state): IExtractionState => {
        return {
          ...state,
          factExtractionPending: true,
          factsExtractionError: false,
          facts: [],
          linkbaseTreeMappings: [],
          extractionCancelled: false,
          factType: "standard",
        };
      })
      .addCase(
        startExtractFacts.fulfilled,
        (state, action): IExtractionState => {
          return {
            ...state,
            factExtractionPending: false,
            facts: action.payload,
            factType:
              action.payload.length > 0 && "tableName" in action.payload[0]
                ? "eurofiling"
                : "standard",
          };
        }
      )
      .addCase(
        startExtractFacts.rejected,
        (state, action): IExtractionState => {
          const extractionCancelled = action.payload === "ERR_CANCELED";
          const newState: IExtractionState = {
            ...state,
            factsExtractionError: !extractionCancelled,
            factExtractionPending: false,
            extractionCancelled: extractionCancelled,
          };
          if (extractionCancelled) {
            newState.extractedBasicData = null;
          }
          return newState;
        }
      )
      .addCase(
        startExtractBasicData.pending,
        (state, action): IExtractionState => {
          return {
            ...state,
            basicDataExtractionPending: true,
            basicDataExtractionError: false,
            extractedBasicData: null,
            extractionCancelled: false,
          };
        }
      )
      .addCase(
        startExtractBasicData.fulfilled,
        (state, action): IExtractionState => {
          const newState: IExtractionState = {
            ...JSON.parse(JSON.stringify(state)),
            basicDataExtractionPending: false,
            extractedBasicData: action.payload,
          };
          for (const entryPoint of action.payload.entryPoints || []) {
            const existingMapping = newState.linkbaseTreeMappings.find(
              (mapping) => mapping?.entryPoint?.href === entryPoint.href
            );
            const fromPackage = action.payload.linkbaseTrees?.some(
              (tree) => tree.entryPointHref === entryPoint.href
            );
            if (existingMapping) {
              existingMapping.fromPackage = fromPackage;
            } else {
              newState.linkbaseTreeMappings.push({
                entryPoint: entryPoint,
                fromPackage: fromPackage,
              });
            }
          }
          if (state.factType === "standard") {
            const entryPointHrefsFromFacts: string[] = getUniqueArray([
              ...state.facts
                .filter((f) => (f as IApiFact).entryPointHref)
                .map((ff) => (ff as IApiFact).entryPointHref.toLowerCase()),
            ]);
            const entryPointHrefsToUpdate = entryPointHrefsFromFacts.filter(
              (href) =>
                !newState.linkbaseTreeMappings.some(
                  (mapping) =>
                    mapping?.entryPoint?.href?.toLowerCase() ===
                    href.toLowerCase()
                )
            );
            if (entryPointHrefsToUpdate.length > 0) {
              for (const entryPointHref of entryPointHrefsToUpdate) {
                newState.linkbaseTreeMappings.push({
                  entryPoint: {
                    href: entryPointHref,
                    names: [],
                    namespace: {
                      uri: entryPointHref,
                      abbreviation: "",
                    },
                  },
                });
              }
            }
          }
          return newState;
        }
      )
      .addCase(
        startExtractBasicData.rejected,
        (state, action): IExtractionState => {
          const extractionCancelled = action.payload === "ERR_CANCELED";
          const newState: IExtractionState = {
            ...state,
            basicDataExtractionPending: false,
            basicDataExtractionError: !extractionCancelled,
            extractionCancelled: extractionCancelled,
          };
          if (extractionCancelled) {
            newState.facts = [];
          }
          return newState;
        }
      )
      .addCase(startUpdateLinkbaseTree.pending, (state): IExtractionState => {
        return {
          ...state,
          updatingLinkbaseTreeError: false,
          updatingLinkbaseTree: true,
        };
      })
      .addCase(
        startUpdateLinkbaseTree.rejected,
        (state, action): IExtractionState => {
          const trees = state.extractedBasicData?.linkbaseTrees;
          const clonedTrees = JSON.parse(JSON.stringify(trees));
          if (action.meta.arg.roleTypeId) {
            const targetBranch = getLinkbaseTreeBranchById(
              clonedTrees,
              action.meta.arg.treeId,
              action.meta.arg.roleTypeId,
              action.meta.arg.elementId
            );
            if (targetBranch) {
              targetBranch.errorLoadingChildren = true;
            }
          }
          const newTax: ExtractedTaxonomy = {
            ...(state.extractedBasicData as ExtractedTaxonomy),
            linkbaseTrees: clonedTrees,
          };
          return {
            ...state,
            extractedBasicData: newTax,
            updatingLinkbaseTreeError: true,
            updatingLinkbaseTree: false,
          };
        }
      )
      .addCase(
        startUpdateLinkbaseTree.fulfilled,
        (state, action): IExtractionState =>
          handleUpdateLinkbaseTreesResponse(state, action)
      )
      .addCase(
        startGetLinkbaseTreeByEntryPoint.pending,
        (state): IExtractionState => {
          return {
            ...state,
            getLinkbaseTreeByEntryPointPending: true,
          };
        }
      )
      .addCase(
        startGetLinkbaseTreeByEntryPoint.fulfilled,
        (state, action): IExtractionState => {
          const mappings: LinkbaseTreeMapping[] = JSON.parse(
            JSON.stringify(state.linkbaseTreeMappings)
          );
          const currentMapping = mappings.find(
            (m) => m.entryPoint.href === action.meta.arg.entryPointHref
          );
          if (currentMapping) {
            currentMapping.trees = [
              ...(currentMapping.trees || []),
              action.payload,
            ];
          } else {
            const entryPoint = [
              ...(state.extractedBasicData?.entryPoints || []),
            ].find((ep) => ep.href === action.meta.arg.entryPointHref);
            if (entryPoint) {
              mappings.push({
                entryPoint: entryPoint,
                trees: [action.payload],
              });
            }
          }
          return {
            ...state,
            getLinkbaseTreeByEntryPointPending: false,
            linkbaseTreeMappings: mappings,
          };
        }
      )
      .addCase(
        startGetLinkbaseTreeByEntryPoint.rejected,
        (state, action): IExtractionState => {
          const mappings: LinkbaseTreeMapping[] = JSON.parse(
            JSON.stringify(state.linkbaseTreeMappings)
          );
          const currentMapping = mappings.find(
            (m) => m.entryPoint.href === action.meta.arg.entryPointHref
          );
          if (currentMapping) {
            currentMapping.error = true;
          } else {
            const entryPoint = [
              ...(state.extractedBasicData?.entryPoints || []),
            ].find((ep) => ep.href === action.meta.arg.entryPointHref);
            if (entryPoint) {
              mappings.push({
                entryPoint: entryPoint,
                error: true,
              });
            }
          }
          return {
            ...state,
            getLinkbaseTreeByEntryPointPending: false,
            linkbaseTreeMappings: mappings,
          };
        }
      )
      .addCase(
        startGetReportFileHashCode.fulfilled,
        (state, action): IExtractionState => {
          return {
            ...state,
            reportFileHashCode: action.payload,
          };
        }
      )
      .addCase(
        startGetUsedElementIdsForLinkbaseTree.pending,
        (state): IExtractionState => {
          return {
            ...state,
            updatingLinkbaseTree: true,
          };
        }
      )
      .addCase(
        startGetUsedElementIdsForLinkbaseTree.rejected,
        (state, action): IExtractionState => {
          return {
            ...state,
            updatingLinkbaseTree: false,
          };
        }
      )
      .addCase(
        startGetUsedElementIdsForLinkbaseTree.fulfilled,
        (state, action): IExtractionState => {
          return {
            ...state,
            updatingLinkbaseTree: false,
            linkbaseTreeUsedElementsMapping: [
              ...state.linkbaseTreeUsedElementsMapping,
              {
                treeId: action.meta.arg.treeId,
                roleTypeUsedElementMappings: action.payload,
              },
            ],
          };
        }
      )
      .addCase(
        startGetElementLabelsByNamespaceAndElementName.pending,
        (state): IExtractionState => {
          return {
            ...state,
            updatingElementLabels: true,
          };
        }
      )
      .addCase(
        startGetElementLabelsByNamespaceAndElementName.rejected,
        (state): IExtractionState => {
          return {
            ...state,
            updatingElementLabels: false,
          };
        }
      )
      .addCase(
        startGetElementLabelsByNamespaceAndElementName.fulfilled,
        (state, action): IExtractionState => {
          const mapping: Array<{
            factId: string;
            labels: LabelsForElementByName[];
          }> = [
              ...state.enumerationFactValueLabelMapping,
              {
                factId: action.meta.arg.factId,
                labels: action.payload,
              },
            ];
          return {
            ...state,
            updatingElementLabels: false,
            enumerationFactValueLabelMapping: mapping,
          };
        }
      );
  },
});

export const {
  extractionTypeUpdated,
  extractionErrorsCleared,
  factsUpdated,
  extractionCleared,
} = extractionSlice.actions;

export default extractionSlice.reducer;
