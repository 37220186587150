import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";

import Logout from "@mui/icons-material/Logout";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ParseportImage from "../../src/images/parseport.png";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIconTypeMap,
  Typography,
  styled,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import { useAppSelector } from "../hooks/useAppSelector";
import { Link, useNavigate } from "react-router-dom";
import {
  appBarFixedHeight,
  knowledgeBaseLink,
  textSearchManualLink,
  userManualLink,
} from "../helpers/constants";
import { useState } from "react";
import tokenStorageHelper from "../helpers/tokenHelper";
import HistoryIcon from "@mui/icons-material/History";
import DownloadIcon from "@mui/icons-material/Download";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
interface StyledTabsProps {
  children?: React.ReactNode;
  value: string | false;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-flexContainer": {
    justifyContent: "end",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#8AE991",
  },
});

interface NavbarTab {
  path: string;
  label: string;
  icon?: OverridableComponent<SvgIconTypeMap>;
}

interface NavbarProps {
  pathname?: string;
  tabs?: NavbarTab[];
}

// Renders the top navbar for the application
const Navbar = ({ pathname, tabs }: NavbarProps) => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.manageUsers.currentUser);

  const [userMenuAnchorElement, setUserMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const [supportMenuAnchorElement, setSupportMenuAnchorElement] =
    useState<null | HTMLElement>(null);

  const handleToggleShowMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchorElement((prev) => (prev ? null : event.currentTarget));
  };

  const handleNavigate = (route: string) => {
    setUserMenuAnchorElement(null);
    navigate(route);
  };

  const tabElements = (tabs ?? []).map((tab) => (
    <Tab
      data-insights-id={`${tab.label}Tab`}
      key={tab.label}
      component={Link}
      to={tab.path}
      value={tab.path}
      label={tab.label}
      iconPosition="start"
      icon={tab.icon && <tab.icon />}
      style={{ color: "#fff" }}
      {...a11yProps(tab.label)}
    />
  ));
  let currentPath: string | boolean = pathname ?? window.location.pathname;
  if (!tabs || !tabs.map((t) => t.path).includes(currentPath)) {
    currentPath = false;
  }

  var Version =
    process.env.REACT_APP_VERSION_NUMBER?.split(".").slice(0, 2).join(".") ??
    null;

  return (
    <AppBar
      sx={{
        height: appBarFixedHeight,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: "none",
      }}>
      <Toolbar>
        <img src={ParseportImage} alt="Parseport" style={{ maxHeight: 66 }} />
        {tabs && tabs.length > 0 && (
          <StyledTabs
            value={currentPath}
            sx={{ flexGrow: 1 }} // Push logout to right side of page
          >
            {tabElements}
          </StyledTabs>
        )}
        {user && tokenStorageHelper.isValid && (
          <>
            <Typography
              sx={{
                fontSize: "0.7rem",
                mr: 1,
                color: "#fff",
                position: "absolute",
                right: 0,
                top: 0,
                fontStyle: "italic",
              }}>
              {Version !== null ? <>v {Version}</> : null}
            </Typography>
            <Button
              variant="text"
              color="inherit"
              disableElevation
              startIcon={<QuestionMarkOutlinedIcon />}
              onClick={(e) => setSupportMenuAnchorElement(e.currentTarget)}>
              Help
            </Button>
            <Menu
              open={Boolean(supportMenuAnchorElement)}
              onClose={() => setSupportMenuAnchorElement(null)}
              anchorEl={supportMenuAnchorElement}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}>
              <MenuItem onClick={() => window.open(userManualLink, "_blank")}>
                <ListItemIcon>
                  <DownloadIcon />
                </ListItemIcon>
                <ListItemText primary="User Manual" />
              </MenuItem>
              <MenuItem
                onClick={() => window.open(knowledgeBaseLink, "_blank")}>
                <ListItemIcon>
                  <DownloadIcon />
                </ListItemIcon>
                <ListItemText primary="Knowledge Base" />
              </MenuItem>
              <MenuItem
                onClick={() => window.open(textSearchManualLink, "_blank")}>
                <ListItemIcon>
                  <DownloadIcon />
                </ListItemIcon>
                <ListItemText primary="TextSearch Manual" />
              </MenuItem>
            </Menu>
            <IconButton
              size="large"
              onClick={handleToggleShowMenu}
              sx={{ color: "#fff" }}>
              <AccountCircle />
            </IconButton>
            <Menu
              id="inspector_navbar_user_menu"
              anchorEl={userMenuAnchorElement}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(userMenuAnchorElement)}
              onClose={handleToggleShowMenu}
              PaperProps={{
                sx: {
                  boxShadow: "none",
                },
              }}>
              <MenuItem onClick={() => handleNavigate("/user")}>
                <Avatar />
                <Typography sx={{ ml: 2 }}>{user.name}</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/conversionhistory")}>
                <HistoryIcon sx={{ fontSize: 45 }} />
                <Typography sx={{ ml: 1 }}>
                  <Trans>Conversion History</Trans>
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleNavigate("/logout")}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>
                  <Trans>Log Out</Trans>
                </ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

function a11yProps(value: string) {
  return {
    id: `simple-tab-${value}`,
    "aria-controls": `simple-tabpanel-${value}`,
  };
}

export default Navbar;
