import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";
import { HTMLReactParserOptions } from "html-react-parser";
import { useEffect, useState, useTransition } from "react";
import {
  viewerReportContainerClassName,
  viewerXBRLCell,
} from "../../helpers/constants";
import parse from "html-react-parser";
import LoadinDialog from "../loadingDialog";
import { getCachedText } from "../../helpers/getCachedPackage";
import { useAppDispatch } from "../../hooks/useAppDisplatch";
import { fontsLoadedForReportIdsUpdated } from "../../reducers/internalLifeCycleReducer";
import { useAppSelector } from "../../hooks/useAppSelector";
import { handleXBRLNodeHighlightClasses } from "../../helpers/handleXBRLNodeHighlightClasses";
import { navigateToFactByAttribute } from "../../helpers/navigateToFact";
import { t } from "@lingui/macro";
import { disableStyleWarning } from "../../reducers/mappingReducer";
import { reportReplacedXBRLNodeCustomStyles } from "./reportReplacedXBRLNodeCustomStyles";
import { modifyHtmlForDebugView } from "../../helpers/modifyHtmlForDebugView";
import { IApiFact } from "../../api/types";

interface ScrollableReportContainerProps {
  reportId: string;
  sourceHtmlUrl: string;
  reactParserOptions: HTMLReactParserOptions | undefined;
  isMinimal: boolean;
  willLoadFontResources: boolean;
  highlightAllFacts: boolean;
  highlightErrors: boolean;
  showDebugView: boolean;
  currentZoomScale?: number;
}

const ScrollableReportContainer = ({
  reportId,
  sourceHtmlUrl,
  reactParserOptions,
  isMinimal,
  willLoadFontResources,
  currentZoomScale,
  highlightAllFacts,
  highlightErrors,
  showDebugView,
}: ScrollableReportContainerProps) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startTransition] = useTransition();
  const [renderingReport, setRenderingReport] = useState(true);
  const [reportAsReactElement, setReportAsReactElement] = useState<
    string | JSX.Element | JSX.Element[]
  >();
  const [showStyleWarning, setShowStyleWarning] = useState(true);

  const facts = useAppSelector((state) => state.extract.facts);

  const extractedFactsType = useAppSelector((state) => state.extract.factType);

  const selectedFactIds = useAppSelector(
    (state) => state.internalLifeCycle.selectedFactIds
  );

  const factsSelectionCaller = useAppSelector(
    (state) => state.internalLifeCycle.factSelectionCaller
  );

  const relevantTargetNameSpaces =
    useAppSelector(
      (state) => state.extract?.extractedBasicData?.entryPoints
    )?.map((ep) => ep?.namespace?.uri) || [];


  const errorsByFactId = useAppSelector(
    (state) => state.internalLifeCycle.errorsByFactId
  );

  const isStyleWarningRelevant = useAppSelector(
    (state) =>
      state.map.reports.find((r) => r.id === reportId)?.showStyleWarning ||
      false
  );

  const handleReportLoad = async (showDebugView: boolean): Promise<void> => {
    let sanitizedHtml = await getCachedText(sourceHtmlUrl);
    if (showDebugView) {
      sanitizedHtml = modifyHtmlForDebugView(sanitizedHtml);
    }
    if (sanitizedHtml.length > 0) {
      startTransition(() => {
        const parsed = parse(sanitizedHtml, reactParserOptions);
        setReportAsReactElement(parsed);
        if (!willLoadFontResources) {
          setRenderingReport(false);
        }
      });
    }
  };

  useEffect(() => {
    handleReportLoad(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceHtmlUrl, reactParserOptions]);

  useEffect(() => {
    if (willLoadFontResources && renderingReport) {
      document.fonts.onloadingdone = () => setRenderingReport(false);
      document.fonts.onloadingerror = () => setRenderingReport(false);
      //fallback for cases where events don't fire (can't be 100% predicted based on font-face src property)
      setTimeout(() => {
        dispatch(fontsLoadedForReportIdsUpdated(reportId));
        if (reportAsReactElement !== undefined) {
          setRenderingReport(false);
        }
      }, 4000);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willLoadFontResources]);

  //handle highlighting
  useEffect(() => {
    if (!renderingReport) {
      const reportReplacedXBRLElems = document.querySelectorAll(
        `[data-internal-viewer-id*='${viewerXBRLCell}']`
      );
      if ((reportReplacedXBRLElems?.length || 0) > 0) {
        reportReplacedXBRLElems!.forEach((reportReplacedXBRLElement) => {
          const elem = reportReplacedXBRLElement as HTMLElement;
          const associatedFactId = elem.dataset.associatedFactId;
          const nestingLevel = parseInt(elem.dataset.nestingLevel || "-1");
          handleXBRLNodeHighlightClasses(
            elem,
            selectedFactIds,
            associatedFactId!,
            facts,
            extractedFactsType,
            relevantTargetNameSpaces,
            highlightErrors,
            errorsByFactId,
            highlightAllFacts,
            nestingLevel
          );
        });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    highlightAllFacts,
    highlightErrors,
    selectedFactIds,
    renderingReport,
    extractedFactsType,
  ]);

  //handle navigation
  useEffect(() => {
    if (!renderingReport && factsSelectionCaller !== "viewer_report") {
      const reportReplacedXBRLElems = document.querySelectorAll(
        `[data-internal-viewer-id*='${viewerXBRLCell}']`
      );
      if ((reportReplacedXBRLElems?.length || 0) > 0) {
        const relevantElem = Array.from(reportReplacedXBRLElems).find((elem) =>
          selectedFactIds.includes(
            (elem as HTMLElement).dataset.associatedFactId || ""
          )
        );
        if (relevantElem) {
          const associatedFactId = (relevantElem as HTMLElement).dataset
            .associatedFactId;
          const nodeType = (relevantElem as HTMLElement).dataset.nodeType;
          const isBlock =
            extractedFactsType === "standard"
              ? (facts as IApiFact[])
                .find((f) => f.id === associatedFactId)
                ?.factElement?.type?.toLowerCase()
                .includes("textblockitemtype") || false
              : false;
          const block: ScrollLogicalPosition = isBlock ? "start" : "center";
          const attributeKey = "data-internal-viewer-id";
          const attributeValue = `${viewerXBRLCell}_${associatedFactId}_${nodeType}_${(relevantElem as HTMLElement).id}`;
          setTimeout(() => {
            navigateToFactByAttribute(attributeKey, attributeValue, block);
          }, 1400);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactIds, renderingReport]);

  useEffect(() => {
    if (!renderingReport) {
      handleReportLoad(showDebugView);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDebugView]);

  if (isMinimal) {
    return (
      <>
        <div className={viewerReportContainerClassName}>
          {reportAsReactElement}
        </div>
        {renderingReport && <LoadinDialog title="Rendering report" />}
      </>
    );
  }

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          width: "100%",
          overflow: "clip",
          "& div": { borderStyle: "hidden !important" },
        }}
        className={viewerReportContainerClassName}>
        <div
          style={{
            width: "100%",
            minHeight: "83vh",
            maxHeight: "83vh",
            overflowY: "scroll",
          }}>
          <div
            style={{
              transform: `scale(${currentZoomScale})`,
              padding: 5,
              transformOrigin: "50% 0",
            }}>
            {reportReplacedXBRLNodeCustomStyles}
            {reportAsReactElement}
          </div>
        </div>
      </Paper>
      {renderingReport && <LoadinDialog title="Rendering report" />}
      <Dialog
        open={!renderingReport && isStyleWarningRelevant && showStyleWarning}>
        <DialogTitle>{t`Please Note`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t`This report contains style directives that violate our security policies and therefore were not enforced. This might affect the way the report is displayed in the viewer.`}
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => {
                setShowStyleWarning(false);
                dispatch(disableStyleWarning(reportId));
              }}>
              Ok
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScrollableReportContainer;
